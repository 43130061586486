<script>
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  created() {
    this.store.feature_options = this.authStore.feature_options;
  },
  data() {
    return {
      store: {
        feature_options: {
          customer_session: {
            enable_2fa: false,
          },
        },
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    confirm() {
      const isEnabling = this.store.feature_options.customer_session.enable_2fa;
      const message = isEnabling
        ? 'Voulez-vous vraiment activer la double authentification ?'
        : 'Voulez-vous vraiment désactiver la double authentification ?';

      this.$buefy.dialog.confirm({
        message,
        onCancel: () => {
          this.store.feature_options.customer_session.enable_2fa = !this.store.feature_options.customer_session.enable_2fa;
        },
        onConfirm: () => {
          this.handle(null, (error) => {
            this.store.feature_options = this.authStore.feature_options;
            this.$errorHandlers.axios(error);
          });
        },
      });
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Authentication à 2 facteurs <AppFeatureBadge class="ml-2" position="is-right" planLock="EXPERT" />
    </template>
    <template #content>
      <p>
        Générez l'envoi d'un code par email pour sécuriser les connexions de vos apprenants.
      </p>
    </template>
    <template #form>
      <GoProWrapper planLock="EXPERT" :hasSep="false">
        <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field>
            <b-switch v-model="store.feature_options.customer_session.enable_2fa"
              @input="dataIsValid(isLoading) && confirm()">
              <span class="is-flex is-align-items-center">
                <!-- <span v-if="isLoading" class="loader is-size-5 mr-5" /> -->
                Activer l'authentification à 2 facteurs
              </span>
            </b-switch>
          </b-field>
        </form>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
